jQuery(document).ready(function( $ ) {

    //Add box shadow to nav on scroll
    window.addEventListener('scroll',(e)=>{
        const nav = document.querySelector('.main-nav');
        if(window.pageYOffset>0){
          nav.classList.add("add-shadow");
        }else{
          nav.classList.remove("add-shadow");
        }
      });

      $("#toTop").click(function() {
        $("html, body").animate({
          scrollTop: 0
        }, 1000);
      });

});//End of jQuery wrapper

